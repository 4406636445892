import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        contactJson {
          phone
          email
          mailsubj
          mailbody
          contact_button_text
        }
    }
   `);
  return (
    <div className="call">
      <div className="call-box-top">

        {data.contactJson.email && (
          <div className="call-email">
            Email:<br/>
            <a href={`mailto:${data.contactJson.email}`}>
              {data.contactJson.email}
            </a>
          </div>
        )}
      </div>
      {props.showButton && (
        <div className="call-box-bottom">
          <a href={`mailto:${data.contactJson.email}?subject=${data.contactJson.mailsubj}&body=${data.contactJson.mailbody}`} className="button">{data.contactJson.contact_button_text}</a>
        </div>
      )}
    </div>
  );
};

export default Call;
